import React, { ReactElement } from "react";
import Image from "next/image";
import styles from "./style.module.scss";
export default function WhatIsHomeWarranty(): ReactElement {
    return (
        <section className={styles["mainContainer"]}>
            <div className="flex flex-col lg:flex-row lg:gap-32 gap-16 lg:pt-14 pt-7  lg:pb-14 pb-7 ">
                <div className="max-w-[737px]  mx-auto lg:mx-0 ">
                    <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] ">
                        Your Shield Against the Unexpected
                    </h2>
                    <p className="mt-3   lg:text-lg text-base lg:leading-8 font-light">
                        Welcome to BestHomeWarrantyOffers.com! We understand
                        that your home is more than just four walls; it's your
                        haven and a significant life investment. Safeguarding it
                        isn't just a choice; it's your duty. That's why we're
                        here to acquaint you with the concept of home
                        warranties—a vital guardian for your home's future.
                    </p>
                </div>
                <div className="w-[300px] h-[160px] md:h-[180px] md:w-[337px] md:min-w-[337px] relative mx-auto lg:mx-0 ">
                    <Image
                        src={
                            "/besthomewarrantyoffers/assets/images/whatIsHomeWarrantyImg.png"
                        }
                        alt="image"
                        layout="fill"
                    />
                </div>
            </div>
        </section>
    );
}
