import React from "react";

function HeatingCooling() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="77"
            height="77"
            fill="none"
            viewBox="0 0 77 77"
        >
            <circle cx="38.5" cy="38.5" r="38.5" fill="#4E62A2"></circle>
            <path
                fill="#fff"
                d="M27.22 38.737a1.34 1.34 0 00-1.34-1.34h-6.54a1.34 1.34 0 000 2.68h6.534c.743 0 1.347-.604 1.347-1.34zM29.111 47.878l-4.623 4.624a1.341 1.341 0 101.897 1.897l4.623-4.624a1.341 1.341 0 10-1.897-1.897zM29.111 29.59c.265.265.604.39.949.39a1.341 1.341 0 00.948-2.288l-4.623-4.623a1.341 1.341 0 10-1.897 1.897l4.623 4.623zM33.45 26.06a1.343 1.343 0 001.75.73 1.344 1.344 0 00.724-1.758l-1.32-3.19a1.353 1.353 0 00-1.758-.73 1.34 1.34 0 00-.723 1.751l1.327 3.198zM23.26 33.191l3.191 1.32a1.343 1.343 0 001.751-.73 1.334 1.334 0 00-.73-1.75l-3.19-1.32a1.332 1.332 0 00-1.751.73 1.348 1.348 0 00.73 1.75zM27.48 45.437a1.34 1.34 0 00.722-1.751 1.334 1.334 0 00-1.75-.723l-3.192 1.32a1.343 1.343 0 001.028 2.481l3.191-1.327zM35.207 50.684a1.335 1.335 0 00-1.75.723l-1.32 3.19a1.334 1.334 0 001.24 1.852c.524 0 1.028-.312 1.24-.83l1.32-3.19a1.343 1.343 0 00-.73-1.745zM56.037 38.737l2.686-2.441a1.249 1.249 0 10-1.678-1.85l-3.343 3.037h-7.556a6.022 6.022 0 00-.458-1.366l.047.04 5.24-5.24 4.518-.22a1.247 1.247 0 001.187-1.306 1.247 1.247 0 00-1.307-1.188l-3.621.172.172-3.621a1.247 1.247 0 00-1.188-1.307 1.255 1.255 0 00-1.306 1.187l-.22 4.518-4.994 4.995a6.281 6.281 0 00-1.566-1.015v-6.7l3.038-3.343a1.249 1.249 0 10-1.85-1.679l-1.194 1.314v-.465c0-.69-.558-1.247-1.247-1.247-.69 0-1.241.558-1.241 1.247v32.956c0 .69.557 1.247 1.247 1.247.69 0 1.247-.557 1.247-1.247v-.471l1.194 1.313a1.24 1.24 0 001.758.087c.511-.465.55-1.254.086-1.765l-3.038-3.343v-6.7a6.047 6.047 0 001.572-1.022l-.006.007 4.995 4.995.219 4.517c.033.67.584 1.188 1.247 1.188h.06a1.247 1.247 0 001.187-1.307l-.172-3.622 3.621.172c.684.054 1.274-.497 1.307-1.187a1.247 1.247 0 00-1.187-1.307l-4.518-.219-5.24-5.24-.047.046c.206-.43.352-.889.458-1.366h7.55l3.342 3.038a1.24 1.24 0 001.758-.086c.465-.511.425-1.3-.086-1.765l-2.673-2.44zM29.728 38.737c0 5.3 3.96 9.678 9.101 10.335v-20.67a10.405 10.405 0 00-9.1 10.335z"
            ></path>
        </svg>
    );
}

export default HeatingCooling;
