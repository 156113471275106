import React, { ReactElement } from "react";
import Image from "next/image";

export default function HowIsHomeWarranty(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-44 gap-16 lg:mt-28 mt-14   ">
            <div className="max-w-[574px]  mx-auto lg:mx-0 order-1 lg:order-2  lg:mt-4">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] ">
                    Demystifying Home Warranties
                </h2>
                <p className="mt-3   lg:text-lg text-base lg:leading-8 font-light">
                    Imagine a home warranty as a protective cocoon for your
                    home's vital systems and appliances. It's a service
                    agreement that ensures coverage for major components,
                    shielding you from unforeseen breakdowns. Think of it as
                    your home's trusty ally, always ready to step in when you
                    need it the most.
                </p>
            </div>
            <div className="w-[300px] h-[240px] md:h-[451px] md:w-[490px] md:min-w-[490px] relative mx-auto lg:mx-0 order-2 lg:order-1  lg:ml-16">
                <Image
                    src="/besthomewarrantyoffers/assets/images/howIsHomeWarrantyImg.png"
                    alt="Home Warranty Image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
