import React, { ReactElement } from "react";
import SaveMoney from "./icons/saveMoney";
import SaveTime from "./icons/saveTime";
import BonusBenefits from "./icons/bonusBenefits";
import SharedButton from "../sharedButton";
const benefitsList = [
    {
        title: "Seasoned Expertise",
        description:
            "With years of home warranty industry knowledge, we're well-versed in safeguarding homes. Countless homeowners have entrusted us with their protection.",
        icon: <SaveMoney />,
    },
    {
        title: "Tailored Plans",
        description:
            "Our array of home warranty plans caters to your distinct needs and budget. Whether you're a first-time homeowner or a seasoned pro, we've got your back.",
        icon: <SaveTime />,
    },
    {
        title: "Vast Network",
        description:
            "Our extensive network of reliable service providers ensures swift and dependable repairs whenever you require them.",
        icon: <BonusBenefits />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <section className="lg:mt-28 mt-14 ">
            <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px]  text-center">
                Why BestHomeWarrantyOffers.com?
            </h2>
            <p className="mt-1 lg:text-lg text-base lg:leading-8 font-light text-center">
                BestHomeWarrantyOffers.com is dedicated to fortifying your most
                cherished asset—your home. Here's why we're your ideal choice:
            </p>
            <div className="flex flex-col lg:flex-row justify-center gap-7 mt-8">
                {benefitsList.map((el, index) => (
                    <div
                        key={index}
                        className="sm:w-[371px] w-[300px] lg:mx-0  mx-auto  rounded-[14px] bg-[#F1F4FF]  lg:h-[471px] h-[460px] flex flex-col items-center px-6 relative"
                    >
                        <span className="mt-16 block">{el.icon}</span>
                        <h3 className="mt-9 font-bold lg:text-2xl text-xl text-[#4E62A2]">
                            {el.title}
                        </h3>
                        <p className="text-center mt-4 min-h-[196px] font-light lg:text-lg text-base lg:leading-8">
                            {el.description}
                        </p>
                    </div>
                ))}
            </div>
            <div className="text-center lg:mt-14 mt-7">
                <SharedButton>
                    Secure Your Free Home Warranty Quote Now!
                </SharedButton>
            </div>
        </section>
    );
}
