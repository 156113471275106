import React from "react";

function ElectricalSystems() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="77"
            height="77"
            fill="none"
            viewBox="0 0 77 77"
        >
            <circle cx="38.5" cy="38.5" r="38.5" fill="#4E62A2"></circle>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M44.986 18a.934.934 0 00-.233.044l-2.038.568a.932.932 0 00-.67 1.019l.16 1.223c-.37.238-.72.522-1.048.83l-1.136-.452a.932.932 0 00-1.15.408l-1.048 1.85a.932.932 0 00.233 1.193l.96.742a7.312 7.312 0 00-.16 1.325l-1.135.495a.932.932 0 00-.539 1.092l.553 2.039a.931.931 0 001.02.684l1.223-.16c.23.364.488.712.786 1.034l-.466 1.15a.932.932 0 00.407 1.15l1.835 1.048a.931.931 0 001.194-.247l.757-.99a7.16 7.16 0 001.296.16l.48 1.121a.932.932 0 001.107.539l2.038-.568a.932.932 0 00.67-1.02l-.16-1.222a7.46 7.46 0 001.063-.83l1.12.451a.932.932 0 001.166-.407l1.048-1.85a.932.932 0 00-.248-1.193l-.96-.743c.097-.437.159-.886.174-1.325l1.136-.495a.932.932 0 00.524-1.092l-.553-2.053a.932.932 0 00-1.02-.684l-1.223.175a7.13 7.13 0 00-.786-1.034l.466-1.15a.932.932 0 00-.407-1.15l-1.835-1.049a.931.931 0 00-1.194.248l-.757.975a7.14 7.14 0 00-1.296-.145l-.48-1.136a.933.933 0 00-.874-.568zm1.02 4.048a5.08 5.08 0 012.474.67c2.376 1.35 3.27 4.368 1.937 6.712-1.333 2.343-4.38 3.111-6.756 1.761-2.377-1.35-3.27-4.368-1.936-6.712.895-1.574 2.56-2.444 4.28-2.431z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M35.464 31.992a.932.932 0 00-.859.772l-.408 2.227c-.88.2-1.731.504-2.519.903l-1.776-1.456a.932.932 0 00-1.208.03l-2.475 2.241a.932.932 0 00-.131 1.238l1.31 1.849a10.66 10.66 0 00-1.121 2.373l-2.3.219a.932.932 0 00-.83.888L23 46.58a.932.932 0 00.772.96l2.27.408c.204.864.503 1.691.904 2.461l-1.456 1.733a.932.932 0 00.044 1.237l2.27 2.446a.932.932 0 001.21.131l1.892-1.296c.75.463 1.558.833 2.417 1.107l.218 2.257a.931.931 0 00.888.844l3.35.131a.932.932 0 00.96-.757l.408-2.228c.88-.2 1.718-.517 2.504-.917l1.79 1.456a.932.932 0 001.21-.029l2.474-2.242a.932.932 0 00.131-1.223l-1.31-1.864c.464-.736.847-1.53 1.121-2.373l2.286-.218a.932.932 0 00.844-.888l.146-3.305a.931.931 0 00-.772-.961l-2.271-.394a10.62 10.62 0 00-.917-2.46l1.47-1.747a.932.932 0 00-.043-1.238l-2.272-2.431a.932.932 0 00-1.208-.146l-1.893 1.31a10.913 10.913 0 00-2.417-1.12l-.233-2.243a.932.932 0 00-.888-.844l-3.349-.146a.906.906 0 00-.087 0zm1.136 5.547c.133 0 .258.009.393.015h.014c4.306.185 7.639 3.9 7.455 8.27-.184 4.368-3.823 7.794-8.125 7.614-4.305-.18-7.652-3.9-7.469-8.27.178-4.231 3.607-7.582 7.732-7.629z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default ElectricalSystems;
