import { ReactElement, ReactNode } from "react";
import styles from "./style.module.scss";

export default function SharedButton({
    children,
}: {
    children: ReactNode;
}): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    return (
        <button
            onClick={() => scrollToTop()}
            className={`   py-4 sm:px-16 px-3 sm:text-lg  rounded-[13px] ${styles["animation"]} `}
        >
            {children}
        </button>
    );
}
